* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

footer {
  border-radius: 4px;
  background-color: #eae6ed;
  width: 225px;
  height: 59px;
  padding: 9px;
  position: relative;
  top: 5px;
}
.container {
  width: 256px;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  position: relative;
  margin: 10px 0 5px 10px;
}
.intContainer {
  position: relative;
  top: -10px;
}
.bedNumber {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.25px;
}
.hrTop {
  margin-top: 14.5px;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
}
.loadingExt {
  width: 222px;
  height: 8px;
  border-radius: 100px;

  position: relative;
}
.loadingInt {
  width: 186px;
  height: 4px;
  border-radius: 100px;
  position: absolute;
  top: 2px;
  left: 2px;
}
.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.87);
}
.container-data {
  width: 225px;
  padding: 10px;
}
.mt9 {
  margin-top: 9px;
}
.mt16 {
  margin-top: 16px;
}
.mt20 {
  margin-top: 18px;
}
.bold {
  font-weight: 700;
}

span.MuiTab-wrapper {
  width: 170px;
  height: 22px;
}

span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
}

span.PrivateTabIndicator-colorSecondary-162 {
  background-color: #008000;
}

div.MuiTabs-flexContainer {
  overflow-x: auto;
  overflow-y: hidden;
}

span.MuiTab-wrapper {
  height: auto;
}

@media screen and (max-width: 1240px) {
  * {
    font-size: 11px;
  }
  body {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .MuiTableCell-root,
  .MuiTableCell-body,
  .MuiTableCell-alignLeft {
    width: 20%;
  }
  div.MuiCardContent-root {
    padding: 2px;
  }
  div.MuiCardContent-root:last-child {
    padding-bottom: 0;
  }
  div.makeStyles-dashboard-123 {
    width: 82%;
  }
}

@media screen and (max-width: 600px) {
  td.MuiTableCell-root,
  .MuiTableCell-body,
  .MuiTableCell-alignLeft {
    padding: 12px;
  }
  div.MuiTabs-flexContainer {
    overflow-x: scroll;
  }
}
